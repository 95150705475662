import React from "react";
import './About.css'
import aboutImg from './img/panfletoF.png';
import aboutImg2 from './img/panfletoV.png';
import Like from './img/like.png';
import UnLike from './img/unlike.png';

function About() {
    window.addEventListener("scroll", function () {
        const upToTop = document.querySelector('a.bottom__to__top');
        upToTop.classList.toggle("active", window.scrollY > 0)
    });
    return (
        <>
            <section id="about">
                <div className="box-header">
                    <div className="box-header-logo">
                        {/* <img src={Logo} alt="logo" /> */}
                    </div>
                </div>
                {/* <div className="about component__space" id="About"> */}
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-6">
                            <img src={aboutImg} alt='' className='about__img' />
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <img src={aboutImg2} alt='' className='about__img' />
                        </div>
                    </div>
                    <div className="up__to__top__btn">
                        <a href="#" className="bottom__to__top">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-chevron-up white"
                                viewBox="0 0 16 16"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"
                                />
                            </svg>
                        </a>
                    </div>
                </div>
                <div className="container">
                    <div className="pauta">
                        <h1 className="about__heading">Minha visão</h1>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-6">
                            <div className="pauta">
                                <h3><img src={Like} alt="like" /> O que apoiamos</h3>
                            </div>
                            <div className="">
                                <ul class="list-group">
                                    <li class="list-group-item">Conservadorismo</li>
                                    <li class="list-group-item">Valores cristãos</li>
                                    <li class="list-group-item">A Vida desde a sua concepção</li>
                                    <li class="list-group-item">Policia</li>
                                    <li class="list-group-item">Menos impostos</li>
                                    <li class="list-group-item">Trabalho</li>
                                    <li class="list-group-item">Saúde</li>
                                    <li class="list-group-item">Escola sem partido</li>
                                    <li class="list-group-item">Liberdade de expressão</li>
                                    <li class="list-group-item">Defesa da propriedade</li>
                                    <li class="list-group-item">Agro</li>
                                    <li></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <div className="pauta">
                                <h3><img src={UnLike} alt="unlike" /> Somos contra</h3>
                            </div>
                            <div className="">
                                <ul class="list-group">

                                    <li class="list-group-item">Comunismo</li>
                                    <li class="list-group-item">Aborto</li>
                                    <li class="list-group-item">Censura</li>
                                    <li class="list-group-item">Aumento de impostos</li>
                                    <li class="list-group-item">Doutrinação nas escolas</li>
                                    <li class="list-group-item">Legalização das drogas</li>
                                    <li class="list-group-item">Ideologia de gênero</li>
                                    <li></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* </div> */}
            </section>
        </>
    )
}

export default About;