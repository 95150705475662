import React from "react";
import './Blog.css';
import Blog1 from './img/pl8889.png';
import Blog2 from './img/circo.webp';
import Blog3 from './img/nicolas.webp';
import InstagramPost from "./Instagram";
import InstagramPost2 from "./Instagram2";
import InstagramPost3 from "./Instagram3";

function Blog() {
    
    return (
        <section id="blog">
            <div className="blog component__space" id="Blog">
                <div className="heading">
                    <h1 className="heading">Acompanhe Nossas Redes</h1>
                    <p className="heading p__color"></p>
                    <p className="heading p__color"></p>
                </div>

                <div className="container">
                    <div className="row">

                        <div className="col-sm-12 col-md-4">
                            <div class="card">
                                <div class="card-body">
                                    <InstagramPost />
                                </div>
                            </div>                            
                        </div>

                        <div className="col-sm-12 col-md-4">
                            <div class="card">
                                <div class="card-body">
                                    <InstagramPost2 />
                                </div>
                            </div>                            
                        </div>

                        <div className="col-sm-12 col-md-4">
                            <div class="card">
                                <div class="card-body">
                                    <InstagramPost3 />
                                </div>
                            </div>                            
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default Blog;