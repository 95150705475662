 import About from './About';   
 import Home from './Home';
 import Service from './Service'; 
 import Blog from './Blog'; 
 import Contact from './Contact';
import './App.css';
// import Siteconstruct from './Siteconstruct';
// import WhatsApp from './WhatsApp';

function App() {
  return (
    <div className="App">   
         <Home />
         <About /> 
         <Service />
         <Blog /> 
         <Contact />
         {/* <WhatsApp /> */}
        {/* <Siteconstruct /> */}
    </div>
  );
}

export default App;
