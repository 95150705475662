import { useState, useEffect } from 'react';
import HeaderBg from './img/header.png';
import logoA from './img/logoA.png';
import logoV from './img/logoV.png';


import './Home.css';

function Home() {
  const [isOpen, setIsOpen] = useState(false);
  const [activeItem, setActiveItem] = useState('home');
  const [logo, setLogo] = useState(logoV);

  useEffect(() => {
    const handleScroll = () => {
      const header = document.querySelector('.header');
      const isScrolled = window.scrollY > 0;
      header.classList.toggle('active', isScrolled);
      console.log('Is active:', isScrolled);

      // Troca de logo com base na rolagem
      setLogo(isScrolled ? logoA : logoV);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleItemClick = (item) => {
    setActiveItem(item);
  };

  return (

    <div className='home' id='Home'>
      <div className='home__bg'>
        <div className='header Navbar'>
          <img className='logo' src={logo} alt='Logo' />
          <div className={`nav-items ${isOpen && 'open'}`}>
            <a
              href='#about'
              className={activeItem === 'about' ? 'active' : ''}
              onClick={() => handleItemClick('about')}
            >
              Sobre
            </a>
            <a
              href='#projects'
              className={activeItem === 'projects' ? 'active' : ''}
              onClick={() => handleItemClick('projects')}
            >
              Projetos
            </a>
            <a
              href='#blog'
              className={activeItem === 'blog' ? 'active' : ''}
              onClick={() => handleItemClick('blog')}
            >
              Notícias
            </a>
            <a
              href='#contact'
              className={activeItem === 'contact' ? 'active' : ''}
              onClick={() => handleItemClick('contact')}
            >
              Contato
            </a>
          </div>
          <div
            className={`nav-toggle ${isOpen && 'open'}`}
            onClick={() => setIsOpen(!isOpen)}
          >
            <div className='bar'></div>
          </div>
        </div>
        <div className='home__meta'>
          <img src={HeaderBg} alt='bg-header' />
        </div>
      </div>
    </div>

  );
}

export default Home;
