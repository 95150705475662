import React, { useEffect, useState } from "react";
import WhatsAppIcon from './img/icons8-whatsapp-48.png';
import Facebook from './img/icons8-facebook-48.png';
import Instagram from './img/icons8-instagram-48.png';

import './WhatsAppStyles.css';

const WhatsApp = ({ message }) => {

  const [whatsAppLink, setWhatsAppLink] = useState("");

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const baseURL = "https://wa.me/556281597514";
    const messageParam = encodeURIComponent(`Olá, gostaria de falar sobre: ${message}`);

    if (/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/.test(userAgent)) {
      setWhatsAppLink(`whatsapp://send?phone=556281597514&text=${messageParam}`);
    } else {
      setWhatsAppLink(`${baseURL}?text=${messageParam}`);
    }
  }, [message]);

  return (
    <>
      <div className="social-media">
        <a href={whatsAppLink} target="_blank"><img src={WhatsAppIcon} alt="whatsapp-icon" /></a>
        <a target="_blank" href="https://www.facebook.com/breniogomes"><img src={Facebook} alt="facebook-icon" /></a>
        <a target="_blank" href="https://www.instagram.com/brenio.gomes/"><img src={Instagram} alt="instagram-icon" /></a>
      </div>
    </>
  )
}
export default WhatsApp;