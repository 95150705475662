import React, { useState, useRef } from 'react';
import ContactImage from './img/fundo.fw.png';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './Contact.css';
import WhatsApp from './WhatsApp';

const Contact = () => {
    const [status, setStatus] = useState("Submit");
    const formRef = useRef(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setStatus("Sending...");
        const { name, email, subject, message } = e.target.elements;
        let details = {
            name: name.value,
            email: email.value,
            subject: subject.value,
            message: message.value,
        };
        try {
            let response = await fetch("https://sendmail.gigabytetelecom.com.br:5000/contact", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json;charset=utf-8",
                },
                body: JSON.stringify(details),
            });
            setStatus("Submit");
            let result = await response.json();

            if (result.status === "Message Sent") {
                toast.success("Mensagem enviada com sucesso!", { position: 'top-right', autoClose: 2000 });
                formRef.current.reset();
            } else {
                toast.error("Erro ao enviar mensagem. Tente novamente.", { position: 'top-right', autoClose: 2000 });
            }
        } catch (error) {
            setStatus("Submit");
            toast.error("Erro ao enviar mensagem. Tente novamente.", { position: 'top-right', autoClose: 2000 });
        }
    };

    return (
        <div className="contact-box">
            <section id="contact">
                <div className="container">
                    <div className="contact component__space" id="Contact">
                        <div className="row">
                            <div className="col-sm-12 col-md-6">
                                <div className="contact__box">
                                    <div className="contact__meta">
                                        <h1 className="hire__text">Conte comigo!</h1>
                                        <p className="hire__text">
                                            Estou disponível para trabalhar para nossa cidade. Conecte-se comigo através do telefone:
                                        </p>
                                        <p className="hire__text">
                                            <strong>E-mail: </strong> contato@breniogomes.com.br
                                        </p>
                                    </div>
                                    <div className="input__box">
                                        <form onSubmit={handleSubmit} ref={formRef}>
                                            <input
                                                type='text'
                                                id="name"
                                                required
                                                className="contact name"
                                                placeholder="Seu nome *"
                                            />
                                            <input
                                                type='text'
                                                id="email"
                                                required
                                                className="contact email"
                                                placeholder="Seu Email *"
                                            />
                                            <input
                                                type='text'
                                                className="contact subject"
                                                placeholder="Escreva um assunto *"
                                                id="subject"
                                                required
                                            />
                                            <textarea
                                                className="contact message"
                                                name='message'
                                                id="message"
                                                required
                                                placeholder="Escreva sua mensagem" ></textarea>
                                            <button type="submit" className="view__more contact pointer btn">{status}</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <img src={ContactImage} alt='' className="contact__img" />
                                <WhatsApp />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ToastContainer />
        </div>
    )
};

export default Contact;
