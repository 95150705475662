import React from "react";
import './Service.css';
import Education from './img/cap-graduation-hat-svgrepo-com.svg';
import Healt from './img/medical-medicine-health-3-svgrepo-com.svg';
import Bus from './img/bus-svgrepo-com.svg';
import Nature from './img/nature-conservation-svgrepo-com.svg';
import Security from './img/security-guard-svgrepo-com.svg';
import City from './img/city-block-svgrepo-com.svg';

function Service() {
    return (
        <section id="projects">
            <div className="service component__space" id="Services">
                <div className="heading">
                    <h1 className="heading">Projetos, pautas e realizações</h1>
                    <p className="heading p__color">
                        Juntos por uma cidade melhor! Pré-candidato a vereador, vamos transformar nossa comunidade!
                    </p>
                    <p className="heading p__color">
                        Seu futuro começa agora! Pré-candidato a vereador, construiremos uma cidade mais forte!
                    </p>
                </div>
                <br />
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-4">
                            <div className="service__box pointer">
                                <div className="icon">
                                    <img src={Education} alt="educação" />
                                </div>
                                <div className="service__meta">
                                    <h1 className="service__text">Educação de Qualidade</h1>
                                    <p className="p service__text p__color">
                                        Implementar programas para modernizar escolas, capacitar professores e melhorar os resultados educacionais para crianças e jovens.
                                    </p>

                                </div>
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-4">
                            <div className="service__box pointer">
                                <div className="icon">
                                    <img src={Healt} alt="educação" />
                                </div>
                                <div className="service__meta">
                                    <h1 className="service__text">Saúde e Bem-estar</h1>
                                    <p className="p service__text p__color">
                                        Fortalecer o sistema de saúde local, com mais recursos para unidades básicas, programas de prevenção e acesso a tratamentos.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-4">
                            <div className="service__box pointer">
                                <div className="icon">
                                    <img src={Bus} alt="educação" />
                                </div>
                                <div className="service__meta">
                                    <h1 className="service__text">Mobilidade Urbana</h1>
                                    <p className="p service__text p__color">
                                        Desenvolver infraestrutura para transporte público mais eficiente e seguro, além de criar ciclovias e espaços para pedestres.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-sm-12 col-md-4">
                            <div className="service__box pointer">
                                <div className="icon">
                                    <img src={Nature} alt="educação" />
                                </div>
                                <div className="service__meta">
                                    <h1 className="service__text">Meio Ambiente Sustentável</h1>
                                    <p className="p service__text p__color">
                                        Promover políticas de preservação ambiental, incentivando a reciclagem, plantio de árvores e uso de energias renováveis.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-4">
                            <div className="service__box pointer">
                                <div className="icon">
                                    <img src={Security} alt="educação" />
                                </div>
                                <div className="service__meta">
                                    <h1 className="service__text">Segurança Comunitária</h1>
                                    <p className="p service__text p__color">
                                        Aumentar a presença policial e criar programas de integração com a comunidade para reduzir a criminalidade e aumentar a segurança.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-4">
                            <div className="service__box pointer">
                                <div className="icon">
                                    <img src={City} alt="educação" />
                                </div>
                                <div className="service__meta">
                                    <h1 className="service__text">Desenvolvimento Econômico</h1>
                                    <p className="p service__text p__color">
                                        Apoiar pequenas empresas e startups, além de incentivar a criação de empregos para impulsionar a economia local.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </section>
    )
}

export default Service;
